import React, { useState } from 'react';

import Link from 'next/link';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import { useMediaQuery } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

import GHButton from '../common/GHButton';

import HeaderListItemLink from './HeaderListItemLink';
import HeaderList from './HeaderList';
import HeaderListItemLinkExternal from './HeaderListItemLinkExternal';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      width: '100%',
      position: 'fixed',
      zIndex: 999,
      backgroundColor: 'transparent',
      backgroundImage:
        'linear-gradient(180deg, #fdfffc 85%, rgba(255,255,255,0) 100%)'
    },
    innerRoot: {
      maxWidth: '1140px',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingTop: theme.spacing(2)
    },
    navDiv: {
      width: '100%'
    },
    logo: {
      height: '60px'
    },
    spacer: {
      flex: 1
    },
    content: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: 'flex',
      alignItems: 'center'
    },
    iconButtonText: {
      paddingLeft: theme.spacing(2)
    },
    mobile: {
      textAlign: 'center'
    },
    mobileLinks: {
      backgroundColor: 'transparent',
      paddingBottom: theme.spacing(2),
      backgroundImage:
        'linear-gradient(180deg, #fdfffc 90%, rgba(255,255,255,0) 100%)'
    },
    logoKSG: {
      width: '165px'
    },
    tryForFreeMobileWrapper: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2)
    },
    ksgLogoMobileWrapper: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    features: {
      marginRight: theme.spacing(1),
      transition: '0.4s',
      cursor: 'pointer',
      textDecoration: 'none',
      fontSize: '24px',
      color: theme.palette.primary.light,
      fontWeight: 'bold',
      paddingRight: theme.spacing(1)
    },
    featuresOpen: {
      color: theme.palette.primary.main
    },
    expand: {
      backgroundColor: theme.palette.grey[100]
    },
    noPaddingBottom: {
      paddingBottom: 0
    },
    paddingTop: {
      marginTop: theme.spacing(2)
    },
    mobileNavButton: {
      minWidth: '50px'
    }
  };
});

const Header = () => {
  const classes = useStyles();
  const matchesSm = useMediaQuery('(max-width:1038px)');

  const [open, setOpen] = useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.innerRoot}>
        <div className={classes.content}>
          <div>
            <Link href="/">
              <a onClick={() => setOpen(false)}>
                <img
                  src="https://cdn.governorhub.com/images/GH-animated.svg"
                  title="GovernorHub Logo"
                  className={classes.logo}
                />
              </a>
            </Link>
          </div>

          {matchesSm ? (
            <>
              <div className={classes.spacer} />
              <GHButton
                variant="inverse-outlined"
                onClick={() => setOpen(!open)}
                className={classes.mobileNavButton}
              >
                <FontAwesomeIcon icon={open ? faTimes : faBars} />
              </GHButton>
            </>
          ) : (
            <>
              <div className={classes.navDiv}>
                <HeaderList>
                  <HeaderListItemLinkExternal
                    onClick={() => {
                      setOpen(false);
                      window.open(
                        'https://thehoot.news/',
                        '_blank',
                        'noopener noreferrer'
                      );
                    }}
                  >
                    The Hoot
                  </HeaderListItemLinkExternal>
                  <HeaderListItemLink
                    href="/insights"
                    onClick={() => setOpen(false)}
                  >
                    Community Insights
                  </HeaderListItemLink>
                  <HeaderListItemLinkExternal
                    onClick={() => {
                      setOpen(false);
                      window.open(
                        'https://app.governorhub.com',
                        '_blank',
                        'noopener noreferrer'
                      );
                    }}
                  >
                    Sign in
                  </HeaderListItemLinkExternal>
                </HeaderList>
              </div>
            </>
          )}
        </div>
        {matchesSm ? (
          <div className={classes.mobile}>
            <Collapse in={open} className={classes.mobileLinks}>
              <HeaderList isMobile>
                <HeaderListItemLink
                  href="https://thehoot.news/"
                  onClick={() => setOpen(false)}
                  isMobile
                >
                  The Hoot
                </HeaderListItemLink>
                <HeaderListItemLink
                  href="/insights"
                  onClick={() => setOpen(false)}
                  isMobile
                >
                  Community Insights
                </HeaderListItemLink>
                <HeaderListItemLink
                  href="https://app.governorhub.com"
                  isMobile
                  onClick={() => setOpen(false)}
                >
                  Sign in
                </HeaderListItemLink>
              </HeaderList>
            </Collapse>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Header;
