import { ReactNode } from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
  return {
    list: {
      listStyle: 'none',
      display: 'flex',
      listStylePosition: 'inside',
      margin: 0,
      padding: 0,
      gap: '1rem',
      justifyContent: 'flex-end'
    },
    listMobile: {
      listStyle: 'none',
      textAlign: 'center',
      paddingInlineStart: 0
    }
  };
});

interface Props {
  children: ReactNode;
  isMobile?: boolean;
}

const HeaderList = ({ children, isMobile = false }: Props) => {
  const classes = useStyles();
  return (
    <nav>
      <ul className={isMobile ? classes.listMobile : classes.list}>
        {children}
      </ul>
    </nav>
  );
};

export default HeaderList;
