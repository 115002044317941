import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Link,
  Theme,
  useMediaQuery,
  Typography
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import {
  faFacebook,
  faXTwitter,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons';

import classNames from 'classnames';

import GHContent from '../common/GHContent';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      color: theme.palette.background.default,
      position: 'relative'
    },
    upper: {
      backgroundColor: theme.palette.primary.main,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    upperContent: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8)
    },

    logo: {
      maxWidth: '100%',
      maxHeight: '100px',
      paddingBottom: theme.spacing(2)
    },
    keyLogo: {
      maxWidth: theme.spacing(20)
    },
    innerContent: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      textAlign: 'center'
    },

    list: {
      listStyle: 'none',
      display: 'flex',
      flexDirection: 'column',
      paddingInlineStart: 0
    },

    listItem: {
      display: 'flex',
      alignItems: 'center'
    },
    listItemText: {
      paddingLeft: theme.spacing(2)
    },
    listItemTextIndented: {
      paddingLeft: theme.spacing(3.5)
    },
    link: {
      color: 'inherit',
      pointer: 'cursor',
      textDecoration: 'none',
      transition: '0.4s',
      '&:hover': {
        color: theme.palette.primary.light
      }
    },
    whiteText: { color: theme.palette.background.default },
    keyLogoContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    marginBottom: {
      marginBottom: theme.spacing(2)
    }
  };
});

const Footer = () => {
  const classes = useStyles();
  const matchesSmall = useMediaQuery('(max-width:1010px)');

  const currentYear = new Date().getFullYear();

  return (
    <div className={classes.root}>
      <div className={classes.upper}>
        <GHContent className={classes.upperContent}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              container
              md={4}
              justifyContent={matchesSmall ? 'center' : undefined}
              alignContent="center"
              alignItems="center"
            >
              <img
                src="https://cdn.governorhub.com/images/GH-anima-white-border.svg"
                title="GovernorHub Logo"
                className={classes.logo}
              />

              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.innerContent}
              >
                Everything a governor or trustee needs in one place.
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={3}
              justifyContent={matchesSmall ? 'center' : undefined}
            >
              <div className={classes.innerContent}>
                <ul className={classes.list} />
              </div>
              <div className={classes.innerContent}>
                <ul className={classes.list}>
                  <a
                    href="mailto:support@governorhub.com"
                    className={classes.link}
                  >
                    <li className={classes.listItem}>
                      <FontAwesomeIcon icon={faEnvelope} />
                      <span className={classes.listItemText}>
                        support@governorhub.com
                      </span>
                    </li>
                  </a>
                  <a
                    href="https://www.facebook.com/GovernorHub"
                    className={classes.link}
                  >
                    <li className={classes.listItem}>
                      <FontAwesomeIcon icon={faFacebook} />
                      <span className={classes.listItemText}>Facebook</span>
                    </li>
                  </a>
                  <a href="https://x.com/GovernorHub" className={classes.link}>
                    <li className={classes.listItem}>
                      <FontAwesomeIcon icon={faXTwitter} />
                      <span className={classes.listItemText}>X</span>
                    </li>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/governorhub/"
                    className={classNames(classes.link, classes.marginBottom)}
                  >
                    <li className={classes.listItem}>
                      <FontAwesomeIcon icon={faLinkedin} />
                      <span className={classes.listItemText}>LinkedIn</span>
                    </li>
                  </a>
                  <a
                    href="https://help.governorhub.com/legal/terms-and-conditions"
                    className={classes.link}
                  >
                    <li className={classes.listItem}>
                      <span>Terms and Conditions</span>
                    </li>
                  </a>
                  <a
                    href="https://help.governorhub.com/legal/privacy"
                    className={classes.link}
                  >
                    <li className={classes.listItem}>
                      <span>Privacy Policy</span>
                    </li>
                  </a>
                  <a
                    href="https://help.governorhub.com/legal/cookies"
                    className={classes.link}
                  >
                    <li className={classes.listItem}>
                      <span>Cookie Policy</span>
                    </li>
                  </a>
                  <a
                    href="https://help.governorhub.com/legal/is-governorhub-gdpr-compliant"
                    className={classes.link}
                  >
                    <li className={classes.listItem}>
                      <span>GDPR Compliance</span>
                    </li>
                  </a>
                </ul>
              </div>
            </Grid>
            <Grid
              item
              container
              md={5}
              justifyContent={'center'}
              alignItems="center"
              direction="column"
            >
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.innerContent}
              >
                <b>GovernorHub</b> is a part of{' '}
                <Link
                  underline="none"
                  color="textSecondary"
                  href="https://thekeygroup.com"
                  className={classes.link}
                >
                  <b>The Key Group</b>
                </Link>
              </Typography>
              <div className={classes.keyLogoContainer}>
                <Typography variant="body1" color="textSecondary">
                  &copy; {currentYear}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </GHContent>
      </div>
    </div>
  );
};

export default Footer;
