import '../styles.css';
import { useEffect } from 'react';

import { AppProps } from 'next/app';
import Head from 'next/head';

import { ThemeProvider } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';

import { config, library } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css'; // Import the CSS
config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

import {
  faArrowAltCircleRight,
  faEnvelope
} from '@fortawesome/free-regular-svg-icons';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faXTwitter } from '@fortawesome/free-brands-svg-icons';

library.add(
  faArrowAltCircleRight,
  faEnvelope,
  faFacebook,
  faXTwitter,
  faBars,
  faTimes
);

import theme from '../base/theme';

import Footer from '../components/footer';

import Header from '../components/header';

// This default export is required in a new `pages/_app.js` file.
export default function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }

    const intercomScript = document.createElement('script');
    intercomScript.src = 'intercom.js';
    intercomScript.async = true;
    document.body.appendChild(intercomScript);
    return () => {
      document.body.removeChild(intercomScript);
    };
  }, []);
  return (
    <>
      <Head>
        <title>GovernorHub</title>
        <link rel="icon" type="image/x-icon" href="favicons/favicon.ico" />
        <link
          rel="preload"
          href="https://fonts.thekeysupport.com/2/modernera-regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        ></link>
        <link
          rel="preload"
          as="font"
          href="https://fonts.thekeysupport.com/2/modernera-medium.woff2"
          type="font/woff2"
          crossOrigin="anonymous"
        ></link>
        <link
          rel="preload"
          as="font"
          href="https://fonts.thekeysupport.com/2/modernera-italic.woff2"
          type="font/woff2"
          crossOrigin="anonymous"
        ></link>
        <link
          rel="preload"
          as="font"
          href="https://fonts.thekeysupport.com/2/modernera-bold.woff2"
          type="font/woff2"
          crossOrigin="anonymous"
        ></link>
        <link
          rel="stylesheet"
          href="https://fonts.thekeysupport.com/1/fonts.css"
        />
      </Head>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header></Header>
        <Component {...pageProps} />
        <Footer></Footer>
      </ThemeProvider>
    </>
  );
}
